@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap"rel="stylesheet");
body {
  margin: 0;
  font-family: "Libre Franklin";
  font-size: 1.3rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  background-attachment: fixed;

  --off-white: #dddddd;
  --brunswick-green: #003d39;
  --dutch-white: #e2d4b7;
  --russian-blue: #0d0630;
  --carolina-blue: #74b3ce;
  --fire-engine-red: #c51b29;
  --blue-graident: linear-gradient(
    40deg,
    rgba(26, 48, 89, 1) 30%,
    rgba(152, 187, 211, 1) 180%
  );
  --sinopia: #c73e1d;
  //background-image: var(--blue-graident);
  background-color: #151516;
}
/* Change the selection background and text color */
::selection {
  background: #fbfbfb; /* background color for the selection */
  color: #000; /* text color for the selection */
}

/* For webkit-based browsers (e.g., Chrome, Safari) */
::-webkit-selection {
  background: #fbfbfb;
  color: #000;
}

/* For Mozilla-based browsers (e.g., Firefox) */
::-moz-selection {
  background: #fbfbfb;
  color: #000;
}
